import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   Documents: [],
   isLoadingDocuments: false,
   statusDocuments: "",
   isErrorDocuments: null,
   ////////////////////////////////////
   DocCategoryForClinics: [],
   isLoadingDocCategoryForClinics: false,
   statusDocCategoryForClinics: "",
   isErrorDocCategoryForClinics: null,
};

export const fetchDocuments = createAsyncThunk(
   "Documents/DocumentsList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/clinic/show-documents/${params}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchDocCategoryForClinics = createAsyncThunk(
   "DocCategoryForClinics/DocCategoryForClinicsList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/clinic/get-doccategory/${params}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

const documentSlice = createSlice({
   name: "documentList",
   initialState,
   extraReducers: {
      [fetchDocuments.fulfilled]: (state, { payload }) => {
         state.Documents = payload;
         state.isLoadingDocuments = false;
         state.statusDocuments = "Success";
         state.isErrorDocuments = null;
      },
      [fetchDocuments.pending]: (state, { payload }) => {
         state.Documents = [];
         state.isLoadingDocuments = true;
         state.statusDocuments = "";
         state.isErrorDocuments = null;
      },
      [fetchDocuments.rejected]: (state, { payload }) => {
         state.Documents = [];
         state.isLoadingDocuments = false;
         state.isErrorDocuments = payload;
         state.statusDocuments = "bad req";
      },
      [fetchDocCategoryForClinics.fulfilled]: (state, { payload }) => {
         state.DocCategoryForClinics = payload;
         state.isLoadingDocCategoryForClinics = false;
         state.statusDocCategoryForClinics = "Success";
         state.isErrorDocCategoryForClinics = null;
      },
      [fetchDocCategoryForClinics.pending]: (state, { payload }) => {
         state.DocCategoryForClinics = [];
         state.isLoadingDocCategoryForClinics = true;
         state.statusDocCategoryForClinics = "";
         state.isErrorDocCategoryForClinics = null;
      },
      [fetchDocCategoryForClinics.rejected]: (state, { payload }) => {
         state.DocCategoryForClinics = [];
         state.isLoadingDocCategoryForClinics = false;
         state.isErrorDocCategoryForClinics = payload;
         state.statusDocCategoryForClinics = "bad req";
      },
   },
});

export default documentSlice.reducer;
