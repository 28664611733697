import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   schedulars: [],
   isLoading: false,
   isError: null,
   status: "",

   isLoadingBookAppmtSchedular: false,
   statusBookAppmtSchedular: "",
   isErrorBookAppmtSchedular: ""
};
export const fetchSchedular = createAsyncThunk(
   "schedulars/schedule",
   async (newParams, { rejectWithValue }) => {
      // console.log('newParams', newParams)
      try {
         const { data } = await axiosInstance.get(
            `api/Appointment/get-scheduler?ClinicId=${newParams.inputFields}&currentDate=${newParams.currentDate}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("data Not Found");
      }
   }
);

export const fetchBookAppmtSchedular = createAsyncThunk(
   "BookAppmtSchedular/BookAppmtSchedularList",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.post("/api/Front/book-appointmentscheduler", values);
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const schedularSlice = createSlice({
   name: "schedule",
   initialState,
   extraReducers: {
      [fetchSchedular.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.schedulars = payload.data;
         } else {
            state.schedulars = [];
         }
         state.isLoading = false;
         state.status = "Success";
         state.isError = null
      },
      [fetchSchedular.pending]: (state, { payload }) => {
         state.schedulars = [];
         state.isLoading = true;
         state.status = "request";
         state.isError = null;
      },
      [fetchSchedular.rejected]: (state, { payload }) => {
         state.schedulars = [];
         state.isLoading = false;
         state.isError = payload;
         state.status = "bad req";
      },
      [fetchBookAppmtSchedular.fulfilled]: (state, action) => {
         state.isLoadingBookAppmtSchedular = false;
         state.statusBookAppmtSchedular = "Success";
         state.isErrorBookAppmtSchedular = "";
      },
      [fetchBookAppmtSchedular.pending]: (state, action) => {
         state.isLoadingBookAppmtSchedular = true;
         state.statusBookAppmtSchedular = "pending";
         state.isErrorBookAppmtSchedular = "";
      },
      [fetchBookAppmtSchedular.rejected]: (state, { payload }) => {
         state.isLoadingBookAppmtSchedular = false;
         state.isErrorBookAppmtSchedular = "Rejected";
         state.statusBookAppmtSchedular = "Rejected";
      },
   },
});

export default schedularSlice.reducer;
