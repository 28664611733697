import React from "react";
import { BsDot } from "react-icons/bs";

const Footer = () => {

  const openPrivacyPolicyWindow = () => {
    window.open("/privacy-policy", '_blank');
  };

  const openTermsOfUseWindow = () => {
    window.open("/terms-of-use", '_blank');
  };


  return (
    <footer className="ne__widget__footer">
      <div className="ne__widget__footer-content d-flex justify-content-center pt-2">
        <span style={{ cursor: "pointer" }}
          onClick={openTermsOfUseWindow}>
          Terms of Use
        </span>
        <BsDot className="footer-dot" />
        <span
          style={{ cursor: "pointer" }}
          onClick={openPrivacyPolicyWindow}>
          Privacy Policy
        </span>
      </div>
    </footer>
  );
};

export default Footer;
