import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  dshboardProvider: [],
  isLoading: false,
  isError: null,
  status: '',
};

export const fetchDashboardProvider = createAsyncThunk(
  'dashboardproviders/dshboardProvider',
  async (clinicId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Dashboard/providers/${clinicId}/getprovs`
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dashboardProviderSLice = createSlice({
  name: 'dshboardProvider',
  initialState,
  extraReducers: {
    [fetchDashboardProvider.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess) {
        state.dshboardProvider = payload.data;
      } else {
        state.dshboardProvider = [];
      }
      state.isLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchDashboardProvider.pending]: (state, { payload }) => {
      state.dshboardProvider = [];
      state.isLoading = true;
      state.status = '';
      state.isError = null;
    },
    [fetchDashboardProvider.rejected]: (state, { payload }) => {
      state.dshboardProvider = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = 'bad req';
    },
  },
});

export default dashboardProviderSLice.reducer;
