import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  colectionData: [],
  isLoadingcollectionData: false,

  statuscollection: 'Success',
  isErrorcollection: null,

  unColectionData: [],
  isLoadingUnColectionData: false,
  statusUnColectionData: 'Success',
  isErrorUnColectionData: null,

  // graph
  colectionGraphData: [],
  collectionGraphLoading: false,
  collectionGraphStatus: null,
};

export const fetchcollections = createAsyncThunk(
  'collection/collectionList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Dashboard/providers/${params.inputFields}/collectionsdetails/${params.startDate}/${params.endDate}/true`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchCollectionGraph = createAsyncThunk(
  'collection/collectionGraph',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Dashboard/providers/${params.inputFields}/collectiongraph/${params.startDate}/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchUnallocatedColection = createAsyncThunk(
  'collection/unallocatedColection',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Dashboard/providers/${params.inputFields}/collectionsdetails/${params.startDate}/${params.endDate}/false`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const collectionSlice = createSlice({
  name: 'collectionList',
  initialState,
  extraReducers: {
    [fetchcollections.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.colectionData = payload?.data;
      } else {
        state.colectionData = [];
      }
      state.isLoadingcollectionData = false;
      state.statuscollection = 'Success';
      state.isErrorcollection = null;
    },
    [fetchcollections.pending]: (state, { payload }) => {
      state.isLoadingcollectionData = true;
      state.statuscollection = '';
      state.isErrorcollection = null;
      state.colectionData = [];
    },
    [fetchcollections.rejected]: (state, { payload }) => {
      state.isLoadingcollectionData = false;
      state.isErrorcollection = payload;
      state.statuscollection = 'Rejected';
      state.colectionData = [];
    },
    [fetchUnallocatedColection.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.unColectionData = payload?.data;
      } else {
        state.unColectionData = [];
      }
      state.isLoadingUnColectionData = false;
      state.statusUnColectionData = 'Success';
      state.isErrorUnColectionData = null;
    },
    [fetchUnallocatedColection.pending]: (state, { payload }) => {
      state.isLoadingUnColectionData = true;
      state.statusUnColectionData = '';
      state.isErrorUnColectionData = null;
      state.unColectionData = [];
    },
    [fetchUnallocatedColection.rejected]: (state, { payload }) => {
      state.isLoadingUnColectionData = false;
      state.isErrorUnColectionData = payload;
      state.statusUnColectionData = 'Rejected';
      state.unColectionData = [];
    },
    // colectionGraphData: [],
    // collectionGraphLoading: false,
    // collectionGraphStatus: null,
    [fetchCollectionGraph.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.colectionGraphData = payload?.data;
      } else {
        state.colectionGraphData = [];
      }
      state.collectionGraphLoading = false;
      state.collectionGraphStatus = 'Success';
    },
    [fetchCollectionGraph.pending]: (state, { payload }) => {
      state.colectionGraphData = [];
      state.collectionGraphLoading = true;
      state.collectionGraphStatus = null;
    },
    [fetchCollectionGraph.rejected]: (state, { payload }) => {
      state.colectionGraphData = [];
      state.collectionGraphLoading = false;
      state.collectionGraphStatus = 'error';
    },
  },
});

// export const { updateUser } = GrossSlice.actions;

export default collectionSlice.reducer;
