import React from "react";
import "./ReviewCard.scss";

const ReviewCard = ({ bookedAddress, bookedTime }) => {
  // console.log('bookedTime', bookedTime)
  return (
    <div className="d-flex review_card">
      <div className="ne_widget_sidebar_thumb mr-3">
        <div className="ne_widget_sidebar_avatar">
          <img
            src="/assets/img/avatar/avatar.svg"
            alt="dental_avatar"
            width={"100%"}
          />
        </div>
      </div>
      <div className="ne_card_details">
        <div className="ne_widget_sidebar_info w-100 mb-4">
          <div className="ne-widget_sidebar_item d-flex">
            <div className="ne_widget-sidebar_info-text">
              <h4 className="ne_widget-sidebar_eyebrow mb-2">Doctor</h4>
              <h3 className="ne_widget-sidebar_name">
                {bookedAddress?.title}
              </h3>
            </div>
          </div>
        </div>

        <div className="ne_widget mb-4">
          <h4 className="ne_widget_eyebrow">Location</h4>
          <h3 className="ne_widget_name"> {bookedAddress?.address}</h3>
          <div className="ne_widget-sidebar_group">
            <p className="ne_widget-sidebar_details"></p>
            <a className="ne_link_phone" href={`tel:${bookedAddress?.mob}`}>
              {bookedAddress?.mob}
            </a>
          </div>
        </div>

        <div className="ne_widget">
          <h4 className="ne_widget_eyebrow">Appointment</h4>
          <div className="ne_widget-sidebar_group">
            <p className="ne_widget-sidebar_details">
              <span>
                {bookedTime.weekName},{" "}
                <span className="ne_widget_month">{bookedTime.month}</span>{" "}
                {bookedTime.day} at {bookedTime.time}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
