export const appointmentData = [
  {
    title: "New Patient Exam",
    id: 1,
    isSelect: false,
  },
  {
    title: "Emergency Exam",
    id: 2,
    isSelect: false,
  },
  {
    title: "Aligners & Braces Consultation",
    id: 3,
    isSelect: false,
  },
  {
    title: "Prophy Cleaning",
    id: 4,
    isSelect: false,
  },
  {
    title: "Perio Cleaning",
    id: 5,
    isSelect: false,
  },
];

export const scheduleData = [
  {
    id: 1,
    title: "Scheduling for Me",
    isSelect: false,
  },
  {
    id: 2,
    title: "Scheduling for Someone Else",
    isSelect: false,
  },
];
