import axios from "axios";
import { baseUrl } from "../../config";
import { defaultLogOut } from "../../services";

// Setting up request interceptor
axios.interceptors.request.use((config) => {
  // Set baseURL from external config
  config.baseURL = baseUrl;

  // Retrieve token from localStorage
  const token = localStorage.getItem("_t");

  if (token) {
    // Add Authorization header if token exists
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    // Remove Authorization header if no token is found
    delete axios.defaults.headers.common['Authorization'];
  }

  return config;
});

// Setting up response interceptor
axios.interceptors.response.use(null, (error) => {
  // Check for 401 Unauthorized error
  const expectedError = error.response && error.response.status === 401;

  if (expectedError) {
    // Log out user and redirect to login if 401 error occurs
    console.error("Unauthorized access detected. Logging out...");
    defaultLogOut(); // Assuming this clears the authentication state
    window.location.href = "/login"; // Redirect to login page

    // setTimeout(() => {
    // }, 500);
  } else {
    // Log unexpected errors
    console.error("An unexpected error occurred:", error);
  }

  // Always return a rejected promise with the error
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
