import React from "react";
import "./DoctorCard.scss";

const DoctorCard = ({ name, team }) => {
  return (
    <div className="ne_widget_sidebar_info mx-3 w-100 pb-2">
      <div className="ne-widget_sidebar_item d-flex ">
        <div className="ne_widget_sidebar_thumb mr-3">
          <div className="ne_widget_sidebar_avatar">
            <img
              src="/assets/img/avatar/avatar.svg"
              alt="dental_avatar"
              width={"100%"}
            />
          </div>
        </div>
        <div className="ne_widget-sidebar_info-text ">
          <h4 className="ne_widget-sidebar_eyebrow mb-2">{name}</h4>
          <h3 className="ne_widget-sidebar_name">{team}</h3>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
