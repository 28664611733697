import * as yup from "yup";
const initialValues = {
  // FirstName: "",
  LastName: "",
  email: "",
  Cellphone: "",
  Zip: "",
  city: "",
  Address: "",
  state: "",
  Gender: "",
  // DOB: "",
  Comments: "",
};
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object().shape({
  // FirstName: yup.string().matches(/[A-Za-z]/, "Should contain only alphabets").required("First name is required"),
  LastName: yup.string().matches(/[A-Za-z]/, "Should contain only alphabets").required("Last name is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  Cellphone: yup
    .string().max(10, "Must be exactly 10 digits").min(10, "Must be exactly 10 digits")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  Zip: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    // .min(5, "Must be exactly 5 digits")
    // .max(5, "Must be exactly 5 digits")
    .required("ZIP code is required"),
  Address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),

  Gender: yup.string().required("Gender is required"),
  // DOB: yup.string().required("Date of birth is required"),
  Comments: yup.string().optional("Comments is required"),
});
export { initialValues, validationSchema };
