import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./store/store";
import 'antd/dist/antd.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import AppCentralisedDataStore from "./components/common/AppCentralisedDataStore";
const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <AppCentralisedDataStore>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </AppCentralisedDataStore>
  </Provider>
);

reportWebVitals();
