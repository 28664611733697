import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  appointments: [],
  isLoading: false,
  isError: null,
  status: "",
  clinicForms: [],
  isLoadingClinicForms: false,
  isErrorClinicForms: null,
  statusClinicForms: "",
};

export const fetchAppointment = createAsyncThunk(
  "appointments/appointmentsList",
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        `/api/Appointment/get-appointments?id=${params.inputFields}&startDate=${params.startDate}&endDate=${params.endDate}`
      );
      return data;
    } catch (error) {
      return rejectWithValue("data Not Found");
    }
  }
);

export const fetchClinicForms = createAsyncThunk(
  "ClinicForms/ClinicFormsList",
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        `/api/Appointment/show-forms/clinics/${params.inputFields}?pid=${params.patientId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);


const appointmentSlice = createSlice({
  name: "appointmentsList",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointment.fulfilled, (state, { payload }) => {
        if (payload.status.isSuccess === true) {
          state.appointments = payload.data;
        } else {
          state.appointments = [];
        }
        state.isLoading = false;
        state.status = "Success";
        state.isError = null;
      })
      .addCase(fetchAppointment.pending, (state) => {
        state.appointments = [];
        state.isLoading = true;
        state.status = "request";
        state.isError = null;
      })
      .addCase(fetchAppointment.rejected, (state, { payload }) => {
        state.appointments = [];
        state.isLoading = false;
        state.isError = payload;
        state.status = "bad req";
      })
      .addCase(fetchClinicForms.fulfilled, (state, { payload }) => {
        // console.log('payload', payload)
        if (payload.status.isSuccess === true) {
          state.clinicForms = payload.data;
        } else {
          state.clinicForms = [];
        }
        state.isLoadingClinicForms = false;
        state.statusClinicForms = "Success";
        state.isErrorClinicForms = null;
      })
      .addCase(fetchClinicForms.pending, (state) => {
        state.clinicForms = [];
        state.isLoadingClinicForms = true;
        state.statusClinicForms = "request";
        state.isErrorClinicForms = null;
      })
      .addCase(fetchClinicForms.rejected, (state, { payload }) => {
        state.clinicForms = [];
        state.isLoadingClinicForms = false;
        state.isErrorClinicForms = payload;
        state.statusClinicForms = "bad req";
      })

  },
});

export default appointmentSlice.reducer;
