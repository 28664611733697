import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   //////////////////////////////
   CustomDashKPIModalData: [],
   isLoadingCustomDashKPIModalData: false,
   statusCustomDashKPIModalData: "",
   isErrorCustomDashKPIModalData: null,
   ///////////////////////
};


export const fetchCustomDashKPIModalData = createAsyncThunk(
   "KPI/KPIList",
   async (params, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.get(
            `/api/dashboard/get-kpidata?kid=${params.kpiID}&clinicid=${params.clinicID}&startdate=${params.startDate}&enddate=${params.endDate} `
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);


const CustomDashboardKPISlice = createSlice({
   name: "CustomDashboard",
   initialState,
   extraReducers: {
      [fetchCustomDashKPIModalData.fulfilled]: (state, { payload }) => {
         state.CustomDashKPIModalData = payload;
         state.isLoadingCustomDashKPIModalData = false;
         state.statusCustomDashKPIModalData = "Success";
         state.isErrorCustomDashKPIModalData = null;
      },
      [fetchCustomDashKPIModalData.pending]: (state, { payload }) => {
         state.CustomDashKPIModalData = [];
         state.isLoadingCustomDashKPIModalData = true;
         state.statusCustomDashKPIModalData = "pending";
         state.isErrorCustomDashKPIModalData = null;
      },
      [fetchCustomDashKPIModalData.rejected]: (state, { payload }) => {
         state.CustomDashKPIModalData = [];
         state.isLoadingCustomDashKPIModalData = false;
         state.isErrorCustomDashKPIModalData = payload;
         state.statusCustomDashKPIModalData = "failed";
      },
   },
});

export default CustomDashboardKPISlice.reducer;
