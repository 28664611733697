import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';
import moment from 'moment';

const initialState = {
  users: [],
  isLoading: false,
  isError: null,
  status: '',
};

export const fetchUsers = createAsyncThunk(
  'users/usersList',
  async (id = null, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`api/user/get-users`);
      const newData = data.map((o) => {
        return {
          ...o,
          createdOn: moment(new Date(o.createdOn)).format('MM/DD/YYYY'),
        };
      });
      return newData;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const usersSlice = createSlice({
  name: 'usersList',
  initialState,

  // reducers: {
  //   updateUser: (state, { payload }) => {
  //     const { users } = current(state);
  //     delete payload.password;
  //     delete payload.confirmPassword;
  //     const data = {
  //       ...payload,
  //       id: v4(),
  //       userName: payload.email,
  //       createdDate: moment(new Date()).format("MM-DD-YYYY hh:mm A"),
  //       createdBy: "",
  //       roles: [payload.Role],
  //     };
  //     if (users && Array.isArray(users)) {
  //       const newUsers = [...users];
  //       newUsers.push(data);
  //       state.users = newUsers;
  //       return state;
  //     } else {
  //       state.users = [data];
  //       return state;
  //     }
  //   },
  // },

  extraReducers: {
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchUsers.pending]: (state, { payload }) => {
      state.isLoading = true;
      state.status = '';
      state.isError = null;
      state.users = [];
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.users = [];
    },
  },
});

// export const { updateUser } = usersSlice.actions;

export default usersSlice.reducer;
