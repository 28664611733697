import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppointmentContext } from "../../context/AppointmentBook/AppointmentBook";
import { getLocationList } from "../../redux/locationSlice/locationSlice";
import { Col, Row } from "react-bootstrap";

import AppointmentDetails from "../../components/appt/AppointmentDetails/AppointmentDetails";
import AppointmentForm from "../../components/appt/AppointmentForm/AppointmentForm";
import AppointmentType from "../../components/appt/AppointmentType/AppointmentType";
import Insurance from "../../components/appt/Insurance/Insurance";
import MapRoute from "../../components/appt/MapRoute/MapRoute";
import Schedule from "../../components/appt/Schedule/Schedule";
import ThankYou from "../../components/appt/ThankYou/ThankYou";
import Title from "../../components/appt/Title/Title";
import Footer from "../../components/Footer/Footer";
import Logo from "../../UI/Logo/Logo";
// import { avlLoc } from "../MapLocation/MapLocation";
import "./AppointmentBook.scss";

const AppointmentBook = () => {
  const { selectedCareer, setSelectedAdd } = useContext(AppointmentContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const locationId = query.get("lid");

  useEffect(() => {
    dispatch(getLocationList());
  }, [dispatch]);

  const { locationList, locationLoading } = useSelector(
    (state) => state.locationList
  );

  useEffect(() => {
    const selAdd = locationList.find((item) => item.id === Number(locationId));

    setSelectedAdd({
      operatoryID: selAdd?.operatoryID,
      providerId: selAdd?.providerId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationList]);



  return (
    <Row className="m-0 p-0 app_container">
      {!selectedCareer.finalStep && (
        <Col className="app_sub_container p-0 m-0">
          <Col md={8} sm={12} className="p-0 app_left ">
            <Col className="p-0  ">
              {selectedCareer.firstStep && (
                <Col className="mt-5 ne_widget-appt-type">
                  <Logo />
                  <Col className="mt-5 mb-3">
                    <Title title="What type of appointment would you like to schedule?" />
                  </Col>
                  <Col className="mb-4">
                    <AppointmentType />
                  </Col>
                </Col>
              )}

              {selectedCareer.secondStep && (
                <Col className="my-5 ne_widget-appt-type">
                  <Col className="mb-3">
                    <Title title="Choose your insurance" />
                  </Col>
                  <Col>
                    <Insurance />
                  </Col>
                </Col>
              )}

              {selectedCareer.thirdStep && (
                <Col className="p-0 fixed">
                  <AppointmentDetails />
                </Col>
              )}

              {selectedCareer.fourthStep && (
                <Col className="my-5 ne_widget-appt-type">
                  <Logo />
                  <Col className="mt-5 mb-3">
                    <Title title="Are you scheduling this appointment for you, or someone else?" />
                  </Col>
                  <Col className="mb-4">
                    <Schedule />
                  </Col>
                </Col>
              )}
            </Col>
            <Col className="d-flex py-3 ne_footer align-items-end justify-content-center">
              <Footer />
            </Col>
          </Col>

          <Col className="p-0 m-0 app_right" md={4} sm={12}>
            {!locationLoading && (
              <MapRoute
                locationList={locationList}
              // locationLoading={locationLoading}
              />
            )}
          </Col>
        </Col>
      )}

      {selectedCareer.finalStep && (
        <Row className="m-0 p-0">
          <Col className="mt-5">
            <Logo />
            <Col className="mb-4 ne_widget-appt-form">
              <AppointmentForm />
            </Col>
            <Col className="d-flex pt-5 pb-5 ne_footer align-items-center justify-content-center mt-3">
              <Footer />
            </Col>
          </Col>
        </Row>
      )}

      {selectedCareer.success && (
        <Row className="m-0 p-0">
          <Col className="mt-5">
            <Logo />
            <Col className="mb-4 ne_widget-appt-form">
              <ThankYou />
            </Col>
            <Col className="d-flex pt-5 pb-5 ne_footer align-items-center justify-content-center mt-5">
              <Footer />
            </Col>
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default AppointmentBook;
