import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   adminReportData: [],
   statusAdminReport: "",
   isErrorAdminReport: null,
   isLoadingAdminReport: false,
};


export const fetchAdminReport = createAsyncThunk(
   "admin/adminReport",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(
            `/api/Practice/get-adminreport?startdate=${params.startDate}&enddate=${params.endDate}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("data Not Found");
      }
   }
);

export const clearadminReport = createAction('admin/clearadminReport');

const adminReportSlice = createSlice({
   name: "adminReportList",
   initialState,
   reducers: {
      resetData(state, action) {
         return initialState
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchAdminReport.fulfilled, (state, { payload }) => {
            if (payload.status.isSuccess === true){
               state.adminReportData = payload.data;
            }else{
               state.adminReportData = [];
            }
            state.isLoadingAdminReport = false;
            state.statusAdminReport = "Success";
            state.isErrorAdminReport = false;
         })
         .addCase(fetchAdminReport.pending, (state) => {
            state.adminReportData = [];
            state.isLoadingAdminReport = true;
            state.statusAdminReport = "";
            state.isErrorAdminReport = false;
         })
         .addCase(fetchAdminReport.rejected, (state) => {
            state.adminReportData = [];
            state.isLoadingAdminReport = false;
            state.isErrorAdminReport = true;
            state.statusAdminReport = "Rejected";
         })
         .addCase(clearadminReport, (state) => {
            // Reset the state when clearadminReport action is dispatched
            return initialState;
         });
   },
});

export default adminReportSlice.reducer;
export const { resetData } = adminReportSlice.actions