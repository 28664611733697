import { useState } from "react";
import { Form } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
const TextInput = ({
  name,
  error,
  type,
  display,
  touched,
  required,
  hidden,
  view,
  onBlur,
  ...rest
}) => {
  const [showPass, setshowPass] = useState(type);
  return hidden ? (
    ""
  ) : (
    <>
      <div className="mb-3  w-100">
        <Form.Label htmlFor={name} className="">
          {display} {required ? <span className="text-danger">*</span> : ""}
        </Form.Label>
        <div className="position-relative mt-1 w-100">
          <Form.Control
            isInvalid={touched && !!error}
            isValid={touched && !error}
            // isValid={value}
            // isInvalid={!!error}
            // isValid={touched && !error}}
            id={name}
            name={name}
            type={showPass}
            {...rest}
          />
          {view === "password" ? (
            <div
              className="position-absolute inputPassWord"
              style={{
                top: "2px",
                right: "2rem",
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
            >
              {showPass === "text" ? (
                <>
                  <AiOutlineEye
                    onClick={() => setshowPass("password")}
                    className="text-xl"
                  />
                </>
              ) : (
                <>
                  <AiOutlineEyeInvisible
                    onClick={() => setshowPass("text")}
                    className="text-xl"
                  />
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {touched && error && (
          <small className="text-danger error_field">{error}</small>
        )}
      </div>
    </>
  );
};

export { TextInput };

const SelectTextInput = ({
  name,
  error,
  display,
  touched,
  required,
  hidden,
  list,
  ...rest
}) => {
  return hidden ? (
    ""
  ) : (
    <>
      <div className="mb-3  w-100">
        <Form.Label htmlFor={name} className="">
          {display} {required ? <span className="text-danger">*</span> : ""}
        </Form.Label>
        <div className="position-relative mt-1 w-100">
          <Form.Select
            aria-label="Default select example"
            id={name}
            name={name}
            {...rest}
            // isValid={}
            // isInvalid={!!error}
            // isValid={!error}
            isValid={touched && !error}
            isInvalid={touched && !!error}
          >
            {list.map((items, index) => (
              <option className="selectOptions" key={index} value={items.value}>
                {items.title}
              </option>
            ))}
          </Form.Select>
        </div>
        {touched && error && (
          <small className="text-danger error_field">{error}</small>
        )}
      </div>
    </>
  );
};
export { SelectTextInput };