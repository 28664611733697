import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Row } from 'react-bootstrap';
import ReviewCard from '../../../UI/Card/ReviewCard/ReviewCard';
import { Loader } from '../../../UI/Loader/Loader';
import { AppointmentContext } from '../../../context/AppointmentBook/AppointmentBook';
import { SelectTextInput, TextInput } from '../../common/inputField';
import { Formik } from 'formik';
import {
  initialValues,
  validationSchema,
} from '../../models/AppointmentBookModel.js';
import Title from '../Title/Title';
import Button from '../../../UI/Button/Button';
import { BiArrowBack } from 'react-icons/bi';
import { DatePicker } from 'antd';
import moment from 'moment';
import { serverUrl } from '../../../config';
import { fetchAppointmentBooking } from '../../../redux/AppointmentSlice/AppointmentFormSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const dateFormat = 'MM/DD/YYYY';

const selectOptions = [
  { value: '', title: 'Choose ' },
  { value: 'Male', title: 'Male' },
  { value: 'Female', title: 'Female' },
  { value: 'Others', title: 'Others' },
];

const HowHeard = [
  { value: '', title: 'Choose ' },
  { value: 'Call Center - Rebooking', title: 'Call Center - Rebooking' },
  { value: 'Direct Mail', title: 'Direct Mail' },
  { value: 'Employers', title: 'Employers' },
  { value: 'Facebook', title: 'Facebook' },
  { value: 'Flyers', title: 'Flyers' },
  { value: 'Friend', title: 'Friend' },
  { value: 'Friends and Family', title: 'Friends and Family' },
  { value: 'Google Search', title: 'Google Search' },
  { value: 'Instagram', title: 'Instagram' },
  { value: 'Insurance Company', title: 'Insurance Company' },
  { value: 'Internet', title: 'Internet' },
  { value: 'Online Search', title: 'Online Search' },
  { value: 'Other', title: 'Other' },
  { value: 'Outbound Phone Call', title: 'Outbound Phone Call' },
  { value: 'Referral', title: 'Referral' },
  { value: 'Relative', title: 'Relative' },
  { value: 'Tiktok', title: 'Tiktok' },
  { value: 'ValPak', title: 'ValPak' },
  { value: 'Youtube', title: 'Youtube' },
];

const AppointmentForm = () => {
  const dispatch = useDispatch();
  const [conscent, setConscent] = useState(false);

  const {
    bookedTime,
    bookedAddress,
    selectedCareer,
    setSelectedCareer,
    // setIsActiveMonth,
    IsNoInsurance,
    AppointmentType,
    InsuranceID,
    ScheduledForSelf,
    setInsuranceID,
    setOtherInsurance,
    OtherInsurance,
    selectedAdd,
    slotId,
  } = useContext(AppointmentContext);

  const { isLoadingAppointmentBooked } = useSelector(
    (state) => state.AppointmentBooking
  );
  // console.log('isLoadingAppointmentBooked', isLoadingAppointmentBooked)
  const [loading, setLoading] = useState(false);
  const [dobError, setDobError] = useState('');
  const [dobValue, setDobValue] = useState(undefined);

  useEffect(() => {
    if (OtherInsurance !== null) {
      setInsuranceID(null);
    }
  }, [OtherInsurance]);
  const DateHandler = (date, dateString) => {
    setDobValue(dateString);
  };
  // console.log('dobValue', dobValue)
  const loginSubmit = async (values, { resetForm }) => {
    if (dobValue === undefined) {
      setDobError('Date of birth is required');
    } else {
      setDobError('');
      try {
        setLoading(true);
        const aptInfo = {
          StartTime: bookedTime.dateJson,
          Sid: slotId,
          OperatoryId: selectedAdd?.operatoryID,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.email,
          Gender: values.Gender,
          HowHeard: values.HowHeard,
          DOB: moment(new Date(dobValue)).format('YYYY-MM-DD'),
          Cellphone: values.Cellphone,
          Zip: values.Zip,
          City: values.city,
          Address: values.Address,
          State: values.state,
          ProviderId: selectedAdd?.providerId,
          Comments: values.Comments,
          AppointmentType: AppointmentType,
          clinicId: bookedAddress.id,
          IsNoInsurance: IsNoInsurance,
          InsuranceID: InsuranceID,
          ScheduledForSelf: ScheduledForSelf,
          OtherInsurance: OtherInsurance,
          BookingFrom: serverUrl === 'production' ? 'Online' : 'NexHealth',
        };

        // console.log('aptInfo', aptInfo)
        dispatch(fetchAppointmentBooking(aptInfo))
          .unwrap()
          .then((res) => {
            if (res?.data.status?.isSuccess == true) {
              resetForm();
              setSelectedCareer({
                ...selectedCareer,
                thirdStep: false,
                fourthStep: false,
                finalStep: false,
                success: true,
              });
              setInsuranceID(null);
              setOtherInsurance(null);
              setLoading(false);
              toast.success(`${res?.data?.data}`, {
                position: 'top-right',
              });
            } else {
              toast.error(`${res?.data.status?.errorMessage}`, {
                position: 'top-right',
              });
              setLoading(false);
            }
            //   if (res.data === "Created Appointment Succesfully") {
            //     resetForm();
            //     setSelectedCareer({
            //       ...selectedCareer,
            //       thirdStep: false,
            //       fourthStep: false,
            //       finalStep: false,
            //       success: true,
            //     });
            //     setInsuranceID(null);
            //     setOtherInsurance(null);
            //     setLoading(false);
            //   }
          })
          .catch((error) => {
            toast.error('Appointment Booking Failed', {
              position: 'top-right',
            });
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.log('error...', error);
      }
    }
  };

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div>
          <header className='py-5 my-4 pl-2'>
            <p
              className='ne_change mb-3 cursors'
              onClick={() => {
                // setIsActiveMonth(false);
                setSelectedCareer({
                  ...selectedCareer,
                  thirdStep: true,
                  fourthStep: false,
                  finalStep: false,
                });
              }}
            >
              <BiArrowBack style={{ marginRight: 5 }} />
              Change your appointment details
            </p>
            <Title title='Please enter your exact information' />
          </header>

          <Row>
            <Col md={6} sm={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={loginSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isInitialValid,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className=''>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='FirstName'
                          type='text'
                          placeholder='First Name'
                          display='First Name'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FirstName}
                          error={errors.FirstName}
                          touched={touched.FirstName}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='LastName'
                          type='text'
                          placeholder='Last Name'
                          display='Last Name'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LastName}
                          error={errors.LastName}
                          touched={touched.LastName}
                          required
                        />
                      </Col>
                      <Col lg={12} sm={12}>
                        <TextInput
                          name='email'
                          type='email'
                          placeholder='Email'
                          display='Email'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={errors.email}
                          touched={touched.email}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='Cellphone'
                          type='text'
                          placeholder='Phone Number'
                          display='Phone Number'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Cellphone}
                          error={errors.Cellphone}
                          touched={touched.Cellphone}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='Address'
                          type='text'
                          placeholder='Address'
                          display='Address'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Address}
                          error={errors.Address}
                          touched={touched.Address}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='city'
                          type='text'
                          placeholder='City'
                          display='City'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          error={errors.city}
                          touched={touched.city}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='state'
                          type='text'
                          placeholder='State'
                          display='State'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                          error={errors.state}
                          touched={touched.state}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <TextInput
                          name='Zip'
                          type='text'
                          placeholder='ZIP code'
                          display='ZIP code'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Zip}
                          error={errors.Zip}
                          touched={touched.Zip}
                          required
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <Form.Label style={{ marginTop: '0.7rem' }}>
                          Date of birth <span className='text-danger'>*</span>
                        </Form.Label>
                        <div className=''>
                          <DatePicker
                            className='w-100 is-valid'
                            onChange={DateHandler}
                            allowClear={true}
                            format={dateFormat}
                          />
                          <br />
                          <span className='text-danger'>{dobError}</span>
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        <SelectTextInput
                          name='Gender'
                          type='number'
                          placeholder='Gender'
                          display='Gender'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Gender}
                          error={errors.Gender}
                          touched={touched.Gender}
                          required
                          list={selectOptions}
                        />
                      </Col>
                      <Col lg={12} sm={12}>
                        <SelectTextInput
                          name='HowHeard'
                          type='number'
                          placeholder='How did you hear about us'
                          display='How did you hear about us'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.HowHeard}
                          error={errors.HowHeard}
                          touched={touched.HowHeard}
                          required
                          list={HowHeard}
                        />
                      </Col>
                      <Col lg={12} sm={12}>
                        <Form.Group className='my-1'>
                          <Form.Label>Comments</Form.Label>
                          <textarea
                            type='text'
                            className={`form-control shadow-none  ${
                              !!touched.Comments && errors.Comments
                                ? 'is-invalid '
                                : values.Comments && 'is-valid'
                            }`}
                            name='Comments'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Comments}
                            touched={touched.Comments}
                            autoComplete='off'
                            placeholder='Comments'
                          />
                          {errors.Comments && touched.Comments && (
                            <small className='text-danger'>
                              {errors.Comments}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col sm={12} md={12} lg={12}>
                        <input
                          type='checkbox'
                          name='conscent'
                          id=''
                          onChange={(e) => setConscent(e.target.checked)}
                        />
                        &nbsp;
                        <strong>
                          By proceeding, you agree to receive automated
                          marketing and promotional communications messages from
                          us. Terms and Privacy Policy can be found at
                          dentalbookingonline.com/privacy-policy. You can opt
                          out at any time by Reply STOP or unsubscribe.{' '}
                        </strong>
                      </Col>
                    </Row>

                    <Button
                      disabled={!conscent}
                      title={
                        isLoadingAppointmentBooked
                          ? 'Booking...'
                          : 'Book appointment'
                      }
                      type='submit'
                      className={`ne_btn ${
                        conscent ? 'ne_btn_primary' : 'ne_btn_primary_disabled'
                      } mt-4 w-100 d-flex justify-content-center align-items-center`}
                    />
                  </Form>
                )}
              </Formik>
            </Col>
            <Col md={6} sm={12}>
              <h4 className='my-3 ne_review_title'>Review Details</h4>
              <ReviewCard
                bookedAddress={bookedAddress}
                bookedTime={bookedTime}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AppointmentForm;
