export const insData = [
  {
    title: "No insurance",
    id: 1,
    isSelect: false,
  },
  {
    title: "Choose insurance carrier",
    id: 2,
    isSelect: false,
  },
];
