import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   FormsHistory: [],
   isLoadingFormsHistory: false,
   isErrorFormsHistory: null,
   statusFormsHistory: "",
};


export const fetchFormsHistory = createAsyncThunk(
   "FormsHistoryList/FormsHistory",
   async (params, { rejectWithValue }) => {
      try {
         const { data } = await axiosInstance.get(
            `/api/Patient/get-documents/${params}`
         );
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const clearPatientCommunication = createAction('facebook/clearPatientCommunication');

const formsHistorySlice = createSlice({
   name: "formsHistoryList",
   initialState,
   reducers: {
      resetData(state, action) {
         return initialState
      }
   },
   extraReducers: {
      [fetchFormsHistory.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.FormsHistory = [];
         } else {
            state.FormsHistory = payload;
         }
         // state.FormsHistory = payload;
         state.isLoadingFormsHistory = false;
         state.statusFormsHistory = "Success";
         state.isErrorFormsHistory = null;
      },
      [fetchFormsHistory.pending]: (state, { payload }) => {
         state.FormsHistory = [];
         state.isLoadingFormsHistory = true;
         state.statusFormsHistory = "";
         state.isErrorFormsHistory = null;
      },
      [fetchFormsHistory.rejected]: (state, { payload }) => {
         state.FormsHistory = [];
         state.isLoadingFormsHistory = false;
         state.isErrorFormsHistory = payload;
         state.statusFormsHistory = "bad req";
      },
   },
});

export default formsHistorySlice.reducer;
export const { resetData } = formsHistorySlice.actions