import React, { useContext } from "react";
import { AppointmentContext } from "../../../context/AppointmentBook/AppointmentBook";
import { appointmentData } from "../../../data/appointmentData/appointmentData";
import Category from "../../../UI/Category/Category";
import "../../../UI/Category/Category.scss";

const AppointmentType = ({ isAppSelected }) => {
  const {
    selectedCareer,
    setSelectedCareer,
    aptTypeData,
    setAptTypeData,
    setAptTypBtnTitle,
    setAppointmentType,
    AppointmentType
  } = useContext(AppointmentContext);

  const onAppTypeSelect = (id) => {
    const tempAppData = [...appointmentData];
    setSelectedCareer({
      ...selectedCareer,
      secondStep: true,
    });
    appointmentData.map((appItem, appIdx) => {
      if (appItem.id === id) {
        setAptTypBtnTitle(appItem.title);
        return (appItem.isSelect = true);
      } else {
        return (appItem.isSelect = false);
      }
    });
    setAptTypeData(tempAppData);
  };

  return (
    <div>
      <ul>
        {aptTypeData.map((apItem, apIdx) => {
          return (
            <Category
              item={apItem}
              key={apIdx}
              onClick={onAppTypeSelect}
              idx={apItem.id}
              setAppointmentType={setAppointmentType}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default AppointmentType;
