import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  roleList: [],
  status: "",
  isError: null,
  isLoading: false,
  accessRoles: [],
  accessIsLoading: false,
  accessStatus: "",
  accessIsError: null
};

export const fetchRoles = createAsyncThunk(
  "roles/rolesList",
  async (id = null, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/api/Auth/get-roles");
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

export const fetchRoleAccess = createAsyncThunk(
  "roles/rolesAccessList",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/Auth/access-url?RoleID=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

const usersSlice = createSlice({
  name: "rolesList",
  initialState,
  extraReducers: {
    [fetchRoles.fulfilled]: (state, { payload }) => {
      state.roleList = payload;
      state.isLoading = false;
      state.status = "Success";
      state.isError = false;
    },
    [fetchRoles.pending]: (state, { payload }) => {
      state.roleList = [];
      state.isLoading = true;
      state.status = "";
      state.isError = false;
    },
    [fetchRoles.rejected]: (state, { payload }) => {
      state.roleList = [];
      state.isLoading = false;
      state.isError = true;
      state.status = "Rejected";
    },
    [fetchRoleAccess.fulfilled]: (state, { payload }) => {
      state.accessRoles = payload;
      state.accessIsLoading = false;
      state.accessStatus = "Success";
      state.accessIsError = false;
    },
    [fetchRoleAccess.pending]: (state, { payload }) => {
      state.accessRoles = [];
      state.accessIsLoading = true;
      state.accessStatus = "";
      state.accessIsError = false;
    },
    [fetchRoleAccess.rejected]: (state, { payload }) => {
      state.accessRoles = [];
      state.accessIsLoading = false;
      state.accessIsError = true;
      state.accessStatus = "Rejected";
    },
  },
});
export default usersSlice.reducer;
