import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   TxAccpGridList: [],
   isLoadingTxAccpGrid: false,
   isErrorTxAccpGrid: null,
   statusTxAccpGrid: "",
   PtAcceptLinkedTP: [],
   isLoadingPtAcceptLinkedTP: false,
   isErrorPtAcceptLinkedTP: null,
   statusPtAcceptLinkedTP: "",
   PtAcceptUnLinkedTP: [],
   isLoadingPtAcceptUnLinkedTP: false,
   isErrorPtAcceptUnLinkedTP: null,
   statusPtAcceptUnLinkedTP: "",
   PtAccepPtsPresentGrid: [],
   isLoadingPtAccepPtsPresentGrid: false,
   isErrorPtAccepPtsPresentGrid: null,
   statusPtAccepPtsPresentGrid: "",
   PtAcceptGraph: [],
   isLoadingPtAcceptGraph: false,
   isErrorPtAcceptGraph: null,
   statusPtAcceptGraph: "",
   PtAcceptPtsAcceptGrid: [],
   isLoadingPtAcceptPtsAcceptGrid: false,
   isErrorPtAcceptPtsAcceptGrid: null,
   statusPtAcceptPtsAcceptGrid: "",
   PtAcceptPtsNotAcceptGrid: [],
   isLoadingPtAcceptPtsNotAcceptGrid: false,
   isErrorPtAcceptPtsNotAcceptGrid: null,
   statusPtAcceptPtsNotAcceptGrid: "",
   PtAccepCompExams: [],
   isLoadingPtAccepCompExams: false,
   isErrorPtAccepCompExams: null,
   statusPtAccepCompExams: "",
   PracticeIQCollection: [],
   isLoadingPracticeIQCollection: false,
   isErrorPracticeIQCollection: null,
   statusPracticeIQCollection: "",
   PracticeAdjProduction: [],
   isLoadingPracticeAdjProduction: false,
   statusPracticeAdjProduction: "",
   isErrorPracticeAdjProduction: null
};


export const fetchTxAccpGrid = createAsyncThunk(
   "TxAccpGrid/TxAccpGridList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-TreatmentAcceptance?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAcceptLinkedTP = createAsyncThunk(
   "PtAcceptLinkedTP/PtAcceptLinkedTPList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-ptaccept?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAcceptUnLinkedTP = createAsyncThunk(
   "PtAcceptUnLinkedTP/PtAcceptUnLinkedTPList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-ptunlinkeddata?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAcceptGraph = createAsyncThunk(
   "PtAccepGraph/PtAccepGraphList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-servicecharts?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}&filter=${params.ptAcceptFilter}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);


export const fetchPtAccepPtsPresentGrid = createAsyncThunk(
   "PtAccepPtsPresentGrid/PtAccepPtsPresentGridList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-ptspresented?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}&filter=${params.ptAcceptFilter}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAccepPtsAcceptGrid = createAsyncThunk(
   "PtAccepPtsAcceptGrid/PtAccepPtsAcceptGridList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-ptsaccepted?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}&filter=${params.ptAcceptFilter}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAccepPtsNotAcceptGrid = createAsyncThunk(
   "PtAccepPtsAcceptGrid/PtAccepPtsAcceptGridList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-ptsunaccepted?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}&filter=${params.ptAcceptFilter}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);

export const fetchPtAccepCompExams = createAsyncThunk(
   "PtAccepCompExams/PtAccepCompExamsList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-compexams?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}&filter=${params.ptAcceptFilter}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);
export const fetchPracticeIQCollection = createAsyncThunk(
   "PracticeIQCollection/PracticeIQCollectionList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)get-paymentsBypatient?startdate=2023-06-
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-paymentsBypatient?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);
export const fetchPracticeAdjProduction = createAsyncThunk(
   "PracticeAdjProduction/PracticeAdjProductionList",
   async (params, { rejectWithValue }) => {
      // console.log('params', params)get-adjustmentdetails?
      try {
         const { data } = await axiosInstance.get(`/api/Practice/get-adjustmentdetails?ClinicId=${params.inputFields}&startdate=${params.startDate}&enddate=${params.endDate}`);
         return data;
      } catch (error) {
         return rejectWithValue("Data Not Found");
      }
   }
);


const TreatmentAccept = createSlice({
   name: "TxAccpGridList",
   initialState,
   extraReducers: {
      [fetchTxAccpGrid.fulfilled]: (state, { payload }) => {
         state.TxAccpGridList = payload;
         state.isLoadingTxAccpGrid = false;
         state.statusTxAccpGrid = "Success";
         state.isErrorTxAccpGrid = null;
      },
      [fetchTxAccpGrid.pending]: (state, { payload }) => {
         state.TxAccpGridList = [];
         state.isLoadingTxAccpGrid = true;
         state.statusTxAccpGrid = "";
         state.isErrorTxAccpGrid = null;
      },
      [fetchTxAccpGrid.rejected]: (state, { payload }) => {
         state.TxAccpGridList = [];
         state.isLoadingTxAccpGrid = false;
         state.isErrorTxAccpGrid = payload;
         state.statusTxAccpGrid = "bad req";
      },
      [fetchPtAcceptLinkedTP.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAcceptLinkedTP = [];
         } else {
            state.PtAcceptLinkedTP = payload;
         }
         // state.PtAcceptLinkedTP = payload;
         state.isLoadingPtAcceptLinkedTP = false;
         state.statusPtAcceptLinkedTP = "Success";
         state.isErrorPtAcceptLinkedTP = null;
      },
      [fetchPtAcceptLinkedTP.pending]: (state, { payload }) => {
         state.PtAcceptLinkedTP = [];
         state.isLoadingPtAcceptLinkedTP = true;
         state.statusPtAcceptLinkedTP = "";
         state.isErrorPtAcceptLinkedTP = null;
      },
      [fetchPtAcceptLinkedTP.rejected]: (state, { payload }) => {
         state.PtAcceptLinkedTP = [];
         state.isLoadingPtAcceptLinkedTP = false;
         state.isErrorPtAcceptLinkedTP = payload;
         state.statusPtAcceptLinkedTP = "bad req";
      },
      [fetchPtAcceptUnLinkedTP.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAcceptUnLinkedTP = [];
         } else {

            state.PtAcceptUnLinkedTP = payload;
         }
         // state.PtAcceptUnLinkedTP = payload;
         state.isLoadingPtAcceptUnLinkedTP = false;
         state.statusPtAcceptUnLinkedTP = "Success";
         state.isErrorPtAcceptUnLinkedTP = null;
      },
      [fetchPtAcceptUnLinkedTP.pending]: (state, { payload }) => {
         state.PtAcceptUnLinkedTP = [];
         state.isLoadingPtAcceptUnLinkedTP = true;
         state.statusPtAcceptUnLinkedTP = "";
         state.isErrorPtAcceptUnLinkedTP = null;
      },
      [fetchPtAcceptUnLinkedTP.rejected]: (state, { payload }) => {
         state.PtAcceptUnLinkedTP = [];
         state.isLoadingPtAcceptUnLinkedTP = false;
         state.isErrorPtAcceptUnLinkedTP = payload;
         state.statusPtAcceptUnLinkedTP = "bad req";
      },
      [fetchPtAcceptGraph.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAcceptGraph = [];
         } else {
            state.PtAcceptGraph = payload;
         }
         // state.PtAcceptGraph = payload;
         state.isLoadingPtAcceptGraph = false;
         state.statusPtAcceptGraph = "Success";
         state.isErrorPtAcceptGraph = null;
      },
      [fetchPtAcceptGraph.pending]: (state, { payload }) => {
         state.PtAcceptGraph = [];
         state.isLoadingPtAcceptGraph = true;
         state.statusPtAcceptGraph = "";
         state.isErrorPtAcceptGraph = null;
      },
      [fetchPtAcceptGraph.rejected]: (state, { payload }) => {
         state.PtAcceptGraph = [];
         state.isLoadingPtAcceptGraph = false;
         state.isErrorPtAcceptGraph = payload;
         state.statusPtAcceptGraph = "bad req";
      },
      [fetchPtAccepPtsPresentGrid.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAccepPtsPresentGrid = [];
         } else {
            state.PtAccepPtsPresentGrid = payload;
         }
         // state.PtAccepPtsPresentGrid = payload;
         state.isLoadingPtAccepPtsPresentGrid = false;
         state.statusPtAccepPtsPresentGrid = "Success";
         state.isErrorPtAccepPtsPresentGrid = null;
      },
      [fetchPtAccepPtsPresentGrid.pending]: (state, { payload }) => {
         state.PtAccepPtsPresentGrid = [];
         state.isLoadingPtAccepPtsPresentGrid = true;
         state.statusPtAccepPtsPresentGrid = "";
         state.isErrorPtAccepPtsPresentGrid = null;
      },
      [fetchPtAccepPtsPresentGrid.rejected]: (state, { payload }) => {
         state.PtAccepPtsPresentGrid = [];
         state.isLoadingPtAccepPtsPresentGrid = false;
         state.isErrorPtAccepPtsPresentGrid = payload;
         state.statusPtAccepPtsPresentGrid = "bad req";
      },
      [fetchPtAccepPtsAcceptGrid.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAcceptPtsAcceptGrid = [];
         } else {
            state.PtAcceptPtsAcceptGrid = payload;
         }
         // state.PtAcceptPtsAcceptGrid = payload;
         state.isLoadingPtAcceptPtsAcceptGrid = false;
         state.statusPtAcceptPtsAcceptGrid = "Success";
         state.isErrorPtAcceptPtsAcceptGrid = null;
      },
      [fetchPtAccepPtsAcceptGrid.pending]: (state, { payload }) => {
         state.PtAcceptPtsAcceptGrid = [];
         state.isLoadingPtAcceptPtsAcceptGrid = true;
         state.statusPtAcceptPtsAcceptGrid = "";
         state.isErrorPtAcceptPtsAcceptGrid = null;
      },
      [fetchPtAccepPtsAcceptGrid.rejected]: (state, { payload }) => {
         state.PtAcceptPtsAcceptGrid = [];
         state.isLoadingPtAcceptPtsAcceptGrid = false;
         state.isErrorPtAcceptPtsAcceptGrid = payload;
         state.statusPtAcceptPtsAcceptGrid = "bad req";
      },
      [fetchPtAccepPtsNotAcceptGrid.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAcceptPtsNotAcceptGrid = [];
         } else {
            state.PtAcceptPtsNotAcceptGrid = payload;
         }
         // state.PtAcceptPtsNotAcceptGrid = payload;
         state.isLoadingPtAcceptPtsNotAcceptGrid = false;
         state.statusPtAcceptPtsNotAcceptGrid = "Success";
         state.isErrorPtAcceptPtsNotAcceptGrid = null;
      },
      [fetchPtAccepPtsNotAcceptGrid.pending]: (state, { payload }) => {
         state.PtAcceptPtsNotAcceptGrid = [];
         state.isLoadingPtAcceptPtsNotAcceptGrid = true;
         state.statusPtAcceptPtsNotAcceptGrid = "";
         state.isErrorPtAcceptPtsNotAcceptGrid = null;
      },
      [fetchPtAccepPtsNotAcceptGrid.rejected]: (state, { payload }) => {
         state.PtAcceptPtsNotAcceptGrid = [];
         state.isLoadingPtAcceptPtsNotAcceptGrid = false;
         state.isErrorPtAcceptPtsNotAcceptGrid = payload;
         state.statusPtAcceptPtsNotAcceptGrid = "bad req";
      },
      [fetchPtAccepCompExams.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PtAccepCompExams = [];
         } else {
            state.PtAccepCompExams = payload;
         }
         // state.PtAccepCompExams = payload;
         state.isLoadingPtAccepCompExams = false;
         state.statusPtAccepCompExams = "Success";
         state.isErrorPtAccepCompExams = null;
      },
      [fetchPtAccepCompExams.pending]: (state, { payload }) => {
         state.PtAccepCompExams = [];
         state.isLoadingPtAccepCompExams = true;
         state.statusPtAccepCompExams = "";
         state.isErrorPtAccepCompExams = null;
      },
      [fetchPtAccepCompExams.rejected]: (state, { payload }) => {
         state.PtAccepCompExams = [];
         state.isLoadingPtAccepCompExams = false;
         state.isErrorPtAccepCompExams = payload;
         state.statusPtAccepCompExams = "bad req";
      },
      [fetchPracticeIQCollection.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.PracticeIQCollection = payload.data;
         } else {
            state.PracticeIQCollection = [];
         }
         // state.PracticeIQCollection = payload;
         state.isLoadingPracticeIQCollection = false;
         state.statusPracticeIQCollection = "Success";
         state.isErrorPracticeIQCollection = null;
      },
      [fetchPracticeIQCollection.pending]: (state, { payload }) => {
         state.PracticeIQCollection = [];
         state.isLoadingPracticeIQCollection = true;
         state.statusPracticeIQCollection = "";
         state.isErrorPracticeIQCollection = null;
      },
      [fetchPracticeIQCollection.rejected]: (state, { payload }) => {
         state.PracticeIQCollection = [];
         state.isLoadingPracticeIQCollection = false;
         state.isErrorPracticeIQCollection = payload;
         state.statusPracticeIQCollection = "bad req";
      },
      [fetchPracticeAdjProduction.fulfilled]: (state, { payload }) => {
         if (payload.isSuccess === false) {
            state.PracticeAdjProduction = [];
         } else {
            state.PracticeAdjProduction = payload;
         }
         // state.PracticeAdjProduction = payload;
         state.isLoadingPracticeAdjProduction = false;
         state.statusPracticeAdjProduction = "Success";
         state.isErrorPracticeAdjProduction = null;
      },
      [fetchPracticeAdjProduction.pending]: (state, { payload }) => {
         state.PracticeAdjProduction = [];
         state.isLoadingPracticeAdjProduction = true;
         state.statusPracticeAdjProduction = "";
         state.isErrorPracticeAdjProduction = null;
      },
      [fetchPracticeAdjProduction.rejected]: (state, { payload }) => {
         state.PracticeAdjProduction = [];
         state.isLoadingPracticeAdjProduction = false;
         state.isErrorPracticeAdjProduction = payload;
         state.statusPracticeAdjProduction = "bad req";
      },
   },
});


export default TreatmentAccept.reducer;
