import React, { forwardRef, useContext } from "react";
import { FiCheck, FiChevronDown } from "react-icons/fi";
import { AppointmentContext } from "../../../context/AppointmentBook/AppointmentBook";
import { appointmentTypeData } from "../../../data/appointmentTypeData/appointmentTypeData";
import withClickOutside from "../../WithClickOutside/WidthClickOutside";

export const AppointmentTypeModal = ({ setIsAppModal, setBtnTitle }) => {
  const { aptTypeData, setAptTypeData } = useContext(AppointmentContext);

  const onAppointmentList = (item) => {
    const tempApp = [...appointmentTypeData];
    tempApp.map((typeItem, typeIdx) => {
      if (typeItem.id === item.id) {
        setBtnTitle(item.title);
        typeItem.isSelect = true;
      } else {
        typeItem.isSelect = false;
      }
    });
    setAptTypeData(tempApp);
    setIsAppModal(false);
  };

  return (
    <div className="ne_dropdown_menu">
      <ul className="ne_dropdown_list">
        {aptTypeData.map((appTypItem, idx) => {
          return (
            <li
              className="ne-scope py-2 p-0"
              key={idx}
              onClick={() => onAppointmentList(appTypItem)}
            >
              <a className="ne_dropdown_item">
                <span className="ne-binding">{appTypItem.title}</span>
                {appTypItem.isSelect && <FiCheck className="fc_checkmark" />}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const AppointmentList = forwardRef(({ open, setOpen, props }, ref) => {
  // const [isAppModal, setIsAppModal] = useState(false);
  const { aptTypBtnTitle, setAptTypBtnTitle } = useContext(AppointmentContext);

  return (
    <fieldset className="ne_dropdown px-2">
      <button className="ne_btn" type="button" onClick={() => setOpen(!open)}>
        <span className="font">
          {aptTypBtnTitle?.length > 17
            ? aptTypBtnTitle.substring(0, 17) + "..."
            : aptTypBtnTitle}
        </span>
        <FiChevronDown className="down-arrow" />
      </button>
      {open && (
        <div className="apt_type_modal" ref={ref}>
          <AppointmentTypeModal
            setIsAppModal={setOpen}
            setBtnTitle={setAptTypBtnTitle}
          />
        </div>
      )}
    </fieldset>
  );
});

export default withClickOutside(AppointmentList);
