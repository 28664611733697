import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  status: "",
  isLoading: false,
  isError: null,
  statusEditClinic: "",
  isLoadingEditClinic: false,
  isErrorEditClinic: null,
};

export const fetchAddClinic = createAsyncThunk(
  "Clinic/addClinic",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        "api/Clinic/add-clinic",
        values
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEditClinic = createAsyncThunk(
  "Clinic/addClinic",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        "/api/Clinic/update-clinic",
        values
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const addOrEditOrgSlice = createSlice({
  name: "AddOrEditClinic",
  initialState,
  extraReducers: {
    [fetchAddClinic.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.status = "Success";
      state.isError = null;
    },
    [fetchAddClinic.pending]: (state, { payload }) => {
      state.isLoading = true;
      state.status = "";
      state.isError = null;
    },
    [fetchAddClinic.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload;
      state.status = "failed";
    },
    [fetchEditClinic.fulfilled]: (state, { payload }) => {
      state.isLoadingEditClinic = false;
      state.statusEditClinic = "Success";
      state.isErrorEditClinic = null;
    },
    [fetchEditClinic.pending]: (state, { payload }) => {
      state.isLoadingEditClinic = true;
      state.statusEditClinic = "";
      state.isErrorEditClinic = null;
    },
    [fetchEditClinic.rejected]: (state, { payload }) => {
      state.isLoadingEditClinic = false;
      state.isErrorEditClinic = payload;
      state.statusEditClinic = "failed";
    },
  },
});

export default addOrEditOrgSlice.reducer;
