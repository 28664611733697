import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  insuranceCareer: [],
  insuranceCareerLoading: true,
  insuranceCareerError: false,
};

export const insuranceCareerSlice = createSlice({
  name: "locationCareerSlice",
  initialState,
  reducers: {
    insuranceCareerPending: (state) => {
      state.insuranceCareer = [];
      state.insuranceCareerLoading = true;
      state.insuranceCareerError = false;
    },
    insuranceCareerErrored: (state, { payload }) => {
      state.insuranceCareer = [];
      state.insuranceCareerLoading = false;
      state.insuranceCareerError = payload;
    },
    insuranceCareerDetails: (state, { payload }) => {
      state.insuranceCareer = payload;
      state.insuranceCareerLoading = false;
      state.insuranceCareerError = false;
    },
  },
});

// Destructure and export the plain action creators
export const {
  insuranceCareerPending,
  insuranceCareerErrored,
  insuranceCareerDetails,
} = insuranceCareerSlice.actions;

export default insuranceCareerSlice.reducer;

export const insuranceCareerList = () => async (dispatch) => {
  try {
    dispatch(insuranceCareerPending());
    const { data } = await axiosInstance.get(
      `api/Front/get-insurancecarrier`
    );

    dispatch(insuranceCareerDetails(data));
  } catch (error) {
    dispatch(insuranceCareerErrored(true));
  }
};
