import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  usersAudit: [],
  isLoadingAudit: false,
  isErrorAudit: null,
  statusAudit: "",
};

export const fetchUsersAudit = createAsyncThunk(
  "usersAudit/usersAuditList",
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(`/api/Practice/get-audittrail?userid=${params.id}&startDate=${params.startDate}&endDate=${params.endDate}`);
      return data;
    } catch (error) {
      return rejectWithValue("Data Not Found");
    }
  }
);

const usersSlice = createSlice({
  name: "usersAuditList",
  initialState,
  extraReducers: {
    [fetchUsersAudit.fulfilled]: (state, { payload }) => {
      state.usersAudit = payload;
      state.isLoadingAudit = false;
      state.statusAudit = "Success";
      state.isErrorAudit = null;
    },
    [fetchUsersAudit.pending]: (state, { payload }) => {
      state.isLoadingAudit = true;
      state.statusAudit = "";
      state.isErrorAudit = null;
      state.usersAudit = [];
    },
    [fetchUsersAudit.rejected]: (state, { payload }) => {
      state.isLoadingAudit = false;
      state.isErrorAudit = payload;
      state.statusAudit = "Rejected";
      state.usersAudit = [];
    },
  },
});

export default usersSlice.reducer;
