import React from "react";
import "./Button.scss";

const Button = ({ title, onClick, disabled, isFetching, ...props }) => {
  return (
    <button onClick={onClick} {...props} type={props.type} disabled={disabled}>
      {/* {title === "Login" ? (
        <>{isFetching ? "logging...." : <>{title}</>}</>
      ) : (
        ""
      )}
      {title === "Register" ? (
        <>{isFetching ? "Register...." : <>{title}</>}</>
      ) : (
        ""
      )} */}
      {title}
    </button>
  );
};

export default Button;
