import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  status: "",
  isLoading: false,
  message: "",
  isError: null,
};

export const fetchEditUser = createAsyncThunk(
  "org/EditUser",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post("/api/User/update-user", values);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDeleteUser = createAsyncThunk(
  "org/deleteUser",
  async (modify, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.delete(
        `/api/User/delete-user?email=${modify}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addOrEditOrgSlice = createSlice({
  name: "addOrEditUser",
  initialState,
  extraReducers: {
    [fetchEditUser.fulfilled]: (state, action) => {
      state.status = false;
      state.status = "Success";
      state.isError = null;
    },
    [fetchEditUser.pending]: (state, action) => {
      state.isLoading = true;
      state.status = "";
      state.isError = null;
    },
    [fetchEditUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = payload;
      state.status = payload;
    },
  },
});

export default addOrEditOrgSlice.reducer;
