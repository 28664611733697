import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MapRoute from "../../components/appt/MapRoute/MapRoute";
import { getLocationList } from "../../redux/locationSlice/locationSlice";
import { Loader } from "../../UI/Loader/Loader";

export const avlLoc = (locations) => {
  const tempData = [...locations];

  let avlLocation = [];

  tempData.map((item, idx) => {
    const data = {
      id: item.id,
      title: item.clinicName,
      lat: item.latitude,
      lng: item.longitude,
      mob: item?.phoneNumber,
      address: `${item.address} ${item.city} ${item.state} ${item.zip}`,
      isSelected: false,
    };

    if (item.latitude > 0) {
      return avlLocation.push(data);
    }
  });
  return avlLocation;
};

const MapLocation = () => {
  const dispatch = useDispatch();
  const { locationList, locationLoading } = useSelector(
    (state) => state.locationList
  );



  useEffect(() => {
    dispatch(getLocationList());
  }, [dispatch]);

  return (
    <Row className="m-0 p-0">
      {locationLoading && <Loader />}
      <Col className="m-0 p-0">
        {!locationLoading && (
          <MapRoute
            locationList={locationList}
            locationLoading={locationLoading}
          />
        )}
      </Col>
    </Row>
  );
};

export default MapLocation;
