import React from "react";
import "./spinner.scss"

export const Loader = ({ paramsHeight, imgSize, loaderWidth, mb }) => {

  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ height: `${paramsHeight ? paramsHeight + "vh" : "100vh"}` }}
    >
      <img
        className="loader"
        src="/assets/img/spinner/Spinnerloader.svg"
        alt="affordable-spinner"
        style={{ width: `${imgSize ? imgSize : "80px"}`, marginBottom: `${mb ? mb : ""}` }}
      />
    </div>
  );
};


export const Ellipse = () => {
  return (
    <div className="w-100 mt-4 d-flex justify-content-center align-items-center">
      <div className="Ellipse"></div>
    </div>
  );
};
