import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  exceptionData: [],
  statusException: '',
  isErrorException: null,
  isLoadingException: false,
};

// https://api.dentalbookingonline.com/api/User/get-exceptionlogs/{Start}/to/{end}

export const fetchException = createAsyncThunk(
  'exception/exceptionList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/User/get-exceptionlogs/${params.startDate}/to/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('data Not Found');
    }
  }
);

const ExceptionSlice = createSlice({
  name: 'exceptionList',
  initialState,
  extraReducers: {
    [fetchException.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.exceptionData = payload?.data;
      } else {
        state.exceptionData = [];
      }
      state.isLoadingException = false;
      state.statusException = 'Success';
      state.isErrorException = null;
    },
    [fetchException.pending]: (state, { payload }) => {
      state.isLoadingException = true;
      state.statusException = '';
      state.isErrorException = null;
      state.exceptionData = [];
    },
    [fetchException.rejected]: (state, { payload }) => {
      state.isLoadingException = false;
      state.isErrorException = payload;
      state.statusException = 'Rejected';
      state.exceptionData = [];
    },
  },
});

export default ExceptionSlice.reducer;
