import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
  status: "",
  isLoading: false,
  isError: "",
};

export const fetchAddRole = createAsyncThunk(
  "role/addRole",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post("api/Auth/modify-role", values);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDeleteRole = createAsyncThunk(
  "role/EditRole",
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.delete(
        `/api/Auth/delete-roles?roleName=${values}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const addOrEditOrgSlice = createSlice({
  name: "addOrEditOrg",
  initialState,
  extraReducers: {
    [fetchAddRole.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.status = "Success";
      state.isError = "";
    },
    [fetchAddRole.pending]: (state, action) => {
      state.isLoading = true;
      state.status = "pending";
      state.isError = "";
    },
    [fetchAddRole.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = "Rejected";
      state.status = "Rejected";
    },
    [fetchDeleteRole.fulfilled]: (state, action) => {
      state.isLoadingDeleteRole = false;
      state.statusDeleteRole = "Success";
      state.isErrorDeleteRole = "";
    },
    [fetchDeleteRole.pending]: (state, action) => {
      state.isLoadingDeleteRole = true;
      state.statusDeleteRole = "pending";
      state.isErrorDeleteRole = "";
    },
    [fetchDeleteRole.rejected]: (state, { payload }) => {
      state.isLoadingDeleteRole = false;
      state.isErrorDeleteRole = "Rejected";
      state.statusDeleteRole = "Rejected";
    },
  },
});

export default addOrEditOrgSlice.reducer;
