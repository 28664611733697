import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helper/axiosInstance/axiosInstance";

const initialState = {
   CustomDashBoard: [],
   isLoadingCustomDashBoard: false,
   statusCustomDashBoard: "",
   isErrorCustomDashBoard: null,
   //////////////////////////////
   kpi: [],
   isLoadingKPI: false,
   statusKPI: "",
   isErrorKPI: null,
   ///////////////////////
   CustomDashBoardData: [],
   isLoadingCustomDashBoardData: false,
   statusCustomDashBoardData: "",
   isErrorCustomDashBoardData: null,
};

export const fetchCustomDashboard = createAsyncThunk(
   "Dashboard/DashboardList",
   async (values, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.get(
            `/api/dashboard/get-dashboard`
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const fetchKPIs = createAsyncThunk(
   "KPI/KPIList",
   async (params, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.get(
            `/api/dashboard/get-allkpis?did=${params.dashID}&clinicid=${params.clinicID}`
         );
         return data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

export const fetchCustomDashboardData = createAsyncThunk(
   "DashboardData/DashboardDataList",
   async (params, { rejectWithValue }) => {
      try {
         const data = await axiosInstance.get(
            `/api/dashboard/get-kpisdetails?did=${params.dashID}&clinicid=${params.clinicID}&startdate=${params.startDate}&enddate=${params.endDate}`,
         );
         return data.data;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const CustomDashboardSlice = createSlice({
   name: "CustomDashboard",
   initialState,
   extraReducers: {
      [fetchCustomDashboard.fulfilled]: (state, { payload }) => {
         state.CustomDashBoard = payload;
         state.isLoadingCustomDashBoard = false;
         state.statusCustomDashBoard = "Success";
         state.isErrorCustomDashBoard = null;
      },
      [fetchCustomDashboard.pending]: (state, { payload }) => {
         state.CustomDashBoard = [];
         state.isLoadingCustomDashBoard = true;
         state.statusCustomDashBoard = "pending";
         state.isErrorCustomDashBoard = null;
      },
      [fetchCustomDashboard.rejected]: (state, { payload }) => {
         state.CustomDashBoard = [];
         state.isLoadingCustomDashBoard = false;
         state.isErrorCustomDashBoard = payload;
         state.statusCustomDashBoard = "failed";
      },
      [fetchKPIs.fulfilled]: (state, { payload }) => {
         // console.log('payload', payload)
         state.kpi = payload;
         state.isLoadingKPI = false;
         state.statusKPI = "Success";
         state.isErrorKPI = null;
      },
      [fetchKPIs.pending]: (state, { payload }) => {
         state.kpi = [];
         state.isLoadingKPI = true;
         state.statusKPI = "pending";
         state.isErrorKPI = null;
      },
      [fetchKPIs.rejected]: (state, { payload }) => {
         state.kpi = [];
         state.isLoadingKPI = false;
         state.isErrorKPI = payload;
         state.statusKPI = "failed";
      },
      [fetchCustomDashboardData.fulfilled]: (state, { payload }) => {
         if (payload.status.isSuccess === true) {
            state.CustomDashBoardData = payload.data;
         } else {
            state.CustomDashBoardData = [];
         }
         state.isLoadingCustomDashBoardData = false;
         state.statusCustomDashBoardData = "Success";
         state.isErrorCustomDashBoardData = null;
      },
      [fetchCustomDashboardData.pending]: (state, { payload }) => {
         state.CustomDashBoardData = [];
         state.isLoadingCustomDashBoardData = true;
         state.statusCustomDashBoardData = "pending";
         state.isErrorCustomDashBoardData = null;
      },
      [fetchCustomDashboardData.rejected]: (state, { payload }) => {
         state.CustomDashBoardData = [];
         state.isLoadingCustomDashBoardData = false;
         state.isErrorCustomDashBoardData = payload;
         state.statusCustomDashBoardData = "failed";
      },
   },
});

export default CustomDashboardSlice.reducer;
