export const doctorListData = [
  {
    id: 1,
    designation: "Doctor",
    name: "Winter Park Smile Team (GD & HYG)",
  },
  {
    id: 2,
    designation: "Doctor",
    name: "Dr. CAROLINE W L BASTA, DDS",
  },
];

const firstTimeArray = () => {
  const indTimeOne = [
    {
      id: Math.random(),
      time: "8:10 AM",
      doctorId: 1,
      dayId: 1,
      isSelect: false,
    },
    {
      id: Math.random(),
      time: "9:10 AM",
      doctorId: 1,
      dayId: 1,
      isSelect: false,
    },
    {
      id: Math.random(),
      time: "10:15 AM",
      doctorId: 1,
      dayId: 1,
      isSelect: false,
    },
    {
      id: Math.random(),
      time: "11:00 AM",
      doctorId: 1,
      dayId: 1,
      isSelect: false,
    },
    {
      id: Math.random(),
      time: "8:10 PM",
      doctorId: 1,
      dayId: 1,
      isSelect: false,
    },
  ];
  return indTimeOne;
};

const secondTimeArray = () => {
  const indTimeTwo = [
    {
      id: Math.random(),
      time: "8:10 AM",
      doctorId: 1,
      dayId: 1,
    },
    {
      id: Math.random(),
      time: "10:15 AM",
      doctorId: 1,
      dayId: 1,
    },
    {
      id: Math.random(),
      time: "11:00 AM",
      doctorId: 1,
      dayId: 1,
    },
  ];
  return indTimeTwo;
};

export const timeListData = [
  {
    slot: 1,
    timeList: firstTimeArray(),
  },
  {
    slot: 2,
    timeList: [],
  },
  {
    slot: 3,
    timeList: secondTimeArray(),
  },
  {
    slot: 5,
    timeList: firstTimeArray(),
  },
  {
    slot: 4,
    timeList: secondTimeArray(),
  },
];

export const dateList = [
  {
    id: 1,
    day: "THU",
    date: "9",
  },
  {
    id: 2,
    day: "FRI",
    date: "10",
  },
  {
    id: 3,
    day: "SAT",
    date: "11",
  },
  {
    id: 4,
    day: "SUN",
    date: "12",
  },
  {
    id: 5,
    day: "MON",
    date: "13",
  },
];
