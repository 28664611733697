import React, { useContext, useState } from "react";
import { AppointmentContext } from "../../context/AppointmentBook/AppointmentBook";
import "./Category.scss";

const Category = ({
  item,
  idx,
  onClick,
  children,
  setAppointmentType,
  setIsNoInsurance,
  ...props
}) => {
  return (
    <li
      key={idx}
      className={`${
        item.isSelect ? "category_list_active" : "category_list "
      } primary-mg-ver d-flex justify-content-between px-3 py-2 my-2 `}
      onClick={() => (onClick(idx), setAppointmentType(item.title))}
    >
      <span
        // onClick={() => }
        className="list-title "
      >
        {item.title}
      </span>

      {item.isSelect && (
        <img
          src="data:image/svg+xml;utf8,<svg width='500px' height='500px' viewBox='0 0 500 500' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>\a <g id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>\a <g id='tick' fill='%23FFFFFF'>\a <path d='M105.976145,225.771999 C93.4878762,213.266719 73.2266015,213.25293 60.7213218,225.741199 C48.216042,238.229468 48.2022522,258.490743 60.6905214,270.996022 L185.869066,396.345076 C198.365712,408.858744 218.643547,408.862928 231.145356,396.354419 L479.74519,147.621355 C492.238619,135.12123 492.233192,114.859951 479.733067,102.366522 C467.232942,89.873093 446.971664,89.8785208 434.478235,102.378645 L208.521221,328.456754 L105.976145,225.771999 Z' id='Shape-Copy-3'></path>\a </g>\a </g>\a </svg>"
          className="list_active_img"
        />
      )}

      {children}
    </li>
  );
};

export default Category;
