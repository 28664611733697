import React, { useContext } from "react";
import { AppointmentContext } from "../../../context/AppointmentBook/AppointmentBook";
import { FaStarOfLife } from "react-icons/fa";


const Location = () => {
  const { bookedAddress } = useContext(AppointmentContext);

  return (
    <header className="ne_widget_header pt-4 pb-4 d-flex align-items-center mb-2">
      <div className="ne_widget_sidebar mx-3 d-flex p-0 m-0">
        <div className="ne_widget_sidebar_thumb  mr-3">
          <div className="ne_icon_star-of-life d-flex justify-content-end">
            <FaStarOfLife
              className=""
              style={{ fontSize: "2rem", color: "#00cbe6", }}
            />
            {/* <span className="ne_widget_index">1</span> */}
          </div>
        </div>
        <div className="ne_widget">
          <h4 className="ne_widget_eyebrow">Location</h4>
          <h3 className="ne_widget_name">{bookedAddress?.title}</h3>
          <div className="ne_widget-sidebar_group">
            <p className="ne_widget-sidebar_details">
              <span>{bookedAddress?.address}</span>
              {/* <br />
              <span>
                <span> Winter Park, </span>
                <span> FL </span>
                <span> 32792 </span>
              </span> */}
            </p>
            <a className="ne_link_phone" href="tel:8179845419">
              {bookedAddress?.mob}
            </a>
            {/* ngIf: inst.misc.insurances */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Location;
